import initialState from 'SRC/modules/redux/initialState'
import actionTypes from 'SRC/modules/redux/actionTypes'

export const vehiclePartsFilterReducer = (state = initialState.vehicleParts.filter, action) => {
  switch (action.type) {
    case actionTypes.SET_VEHICLE_PARTS_FILTERED_FIELDS:
      return {...state, filteredFields: action.payload}
    case actionTypes.SET_VEHICLE_PARTS_FILTERED_FIELDS_LOADING:
      return {...state, isFilteredFieldsLoading: action.payload}
    default:
      return state
  }
}
