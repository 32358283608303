import React from 'react'
import PropTypes from 'prop-types'
import { normalizeString } from 'SRC/utils'
import Modal from 'react-responsive-modal'
import ReactSVG from 'react-svg'

import { Label } from '../Label'
import { DropdownItem } from '../DropdownItem'
import { DropdownGroup } from '../DropdownGroup'
import { ConfirmButton } from '../ConfirmButton'

import { SelectAll } from '../SelectAll'
import { Search } from '../../Search'
import { Title } from '../../Title'

const modalStyles = {
  modalContainer: {
    overflowY: 'hidden'
  },
  modal: {
    width: '90%',
    maxWidth: '90%',
    height: '90vh',
    borderRadius: 10,
		padding: '.25rem',
		borderRadius: 4,
		backgroundColor: '#eeeeee'
  }
}

const getStringValue = val => val ? val.toString() : ''

export const DropdownMobile = ({
  isDisabled,
	isSearchable,
	options,
	labelDefault,
	id,
	checkedItems,
	title,
	onChange,
	needColon,
	onMouseEnter,
	isRequired,
	isOptional,
  hasSelectAll
}) => {
	const [isModalVisible, setIsModalVisible] = React.useState(false)
	const [filter, setFilter] = React.useState('')

  const checkedItemsValues = Array.isArray(checkedItems) ? checkedItems.map(item => getStringValue(item)) : []

  const findItem = item => {
    const itemLabel = normalizeString(getStringValue(item.label).toLowerCase())
    return itemLabel.indexOf(filter.toLowerCase().normalize()) > -1
  }

  const getFilteredSubGroups = subGroups => {
    if (!subGroups.length) return []
    console.log('getSubGroups subGroups', subGroups)

    return subGroups.reduce((acc, curr) => {
      const subGroupOptions = Array.isArray(curr.options) ? curr.options.filter(findItem) : []

      if (subGroupOptions.length) acc.push({ ...curr, options: subGroupOptions })

      return acc
    }, [])
  }

  const getFilteredItems = () => {
    console.log('getSubGroups items', options)

    return options.reduce((acc, curr) => {
      if (!curr.isGrouped) {
        if (findItem(curr)) acc.push(curr)
        return acc
      }
      
      const groupOptions = Array.isArray(curr.options) ? curr.options.filter(findItem) : []
      const groupSubGroups = Array.isArray(curr.subGroups) ? getFilteredSubGroups(curr.subGroups) : []

      if (groupOptions.length || groupSubGroups.length) {
        acc.push({ ...curr, options: groupOptions, subGroups: groupSubGroups })
      }

      return acc
    }, [])
  }

  const getItems = () => {
    if (!Array.isArray(options) || !options) return []

    if (filter) return getFilteredItems()

    return options
  }

  const onSelectDropdownChange = (e) => {
    const value = e.currentTarget.dataset.value.toString()

    let newItems = [...checkedItemsValues]

    if (checkedItemsValues.includes(value)) {
      newItems = newItems.filter(item => item !== value)
    } else {
      newItems.push(value)
    }

    onChange(newItems)
  }

  const items = getItems()

  return ( 
    <React.Fragment>
      <Title title={title} needColon={needColon} isRequired={isRequired} />

      <div
        className={`select-dropdown-picker ${isDisabled ? 'disabled' : ''}`}
        key={`multiselect-${id}`}
				onClick={() => { setIsModalVisible(true) }}
      >
				<ReactSVG
					src='/icons/022.pretraga-polja-dropdown-strelica.svg'
					beforeInjection={svg => {
						svg.setAttribute('style', 'width: 20px; height: auto; margin-right: 5px;')
					}}
					className='select-dropdown-picker__icon-svg'
				/>
        <Label labelDefault={labelDefault} checkedItems={checkedItemsValues} options={options} />
      </div>

      <Modal
				open={isModalVisible && !isDisabled}
				onClose={() => { setFilter(''); setIsModalVisible(false) }}
				center
				showCloseIcon={false}
				closeOnOverlayClick={false}
				onExited={() => { setFilter(''); setIsModalVisible(false) }}
				styles={modalStyles}
			>
        {!isDisabled ? (
          <div className='select-dropdown-container select-dropdown-multiple select-dropdown-mobile'>
            <Search setFilter={setFilter} isMobile />
            <ul className='select-dropdown-items-mobile'>
              <SelectAll
                show={!isDisabled && hasSelectAll && Array.isArray(options) && options.every(option => !option.isGrouped)}
                checkedItems={checkedItemsValues}
                options={options}
                onChange={onChange}
              />
              
              {items.map((item, index) => item.isGrouped
                ? (
                  <DropdownGroup
                    key={item.id}
                    checkedItems={checkedItemsValues}
                    filter={filter}
                    group={item}
                    hasSelectAll={hasSelectAll}
                    onChange={onChange}
                    onItemChange={onSelectDropdownChange}
                  />
                ) : (
                  <DropdownItem
                    key={`${item.id}-${item.label}`}
                    checkedItems={checkedItemsValues}
                    option={item}
                    onChange={onSelectDropdownChange}
                  />
                )) 
              }
            </ul>

            <ConfirmButton onClick={() => { setFilter(''); setIsModalVisible(false) }} />
          </div>
        ) : null}
      </Modal>
    </React.Fragment>
  )
}

DropdownMobile.propTypes = {
  id: PropTypes.any,
  title: PropTypes.string.isRequired,
  labelDefault: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.any,
    label: PropTypes.string,
    value: PropTypes.any,
    isGrouped: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.any,
      label: PropTypes.string,
      value: PropTypes.any
    }))
  })).isRequired,
  checkedItems: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isSearchable: PropTypes.bool.isRequired,
  hasSelectAll: PropTypes.bool.isRequired,
  isRequired: PropTypes.bool,
  needColon: PropTypes.bool.isRequired,
  onFocus: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onChange: PropTypes.func.isRequired
}

DropdownMobile.defaultProps = {
  title: '',
  options: [],
  checkedItems: [],
  isSearchable: false,
  isDisabled: false,
  needColon: true,
  hasSelectAll: false,
  isRequired: false
}
