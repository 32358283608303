import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { groupMenuItems } from 'SRC/modules/vehicleParts/utils/vehiclePartsUtils'
import { getCurrentSubCategory, getGroupCategories } from 'SRC/modules/categories/selectors'
import { FilterButtons } from 'SRC/modules/layout/Header/components/FilterButtons'
import { getCurrentGroup, getGroups } from 'SRC/modules/groups/selectors'

import { Item } from '../Item'
import { PartsItem } from '../PartsItem'
import { Sausages } from '../Sausages'

import translations from '../../../../../../public/data/translations.json'

const getFilterBtnHref = router => {
  return {
    pathname: '/mobileAdFilter',
    query: router.query
  }
}

const getFilterBtnAs = router => {
  return `/mobilna-pretraga${router.asPath}`
}

const getMenuItems = ({ currentGroup, groups, groupCategories, currentSubCategory, isVehicleParts, vehiclePartGroup, router, locale }) => {
  if (!Array.isArray(groups)) return []

  if (isVehicleParts && Array.isArray(groupMenuItems)) {
    return groupMenuItems.map(item => {
      const as = `/${translations.noviDjeloviUrl[locale]}/${item.seo}`
      const href = { pathname: '/vehicleParts', query: { group: item.seo } }

      return { ...item, href, as, isActive: vehiclePartGroup === item.seo }
    })
  }

  if (!currentGroup || (currentGroup && currentSubCategory && currentGroup.seo === currentSubCategory.seo)) {
    return groups.map(group => {
      const as = `/${group.seo}`
      const href = { pathname: '/category', query: { category: group.seo } }

      const isGroupActive = (router && decodeURI(router.asPath).startsWith(as)) ||
        (currentGroup && currentGroup.seo === group.seo)

      return { ...group, href, as, isActive: isGroupActive }
    })
  }

  return groupCategories.map(category => {
    const as = `/${category.seo}`
    const href = { pathname: '/category', query: { category: category.seo } }

    return { ...category, href, as, isActive: router && decodeURI(router.asPath).startsWith(as) }
  })
}

const Container = ({ groups, groupCategories, currentGroup, currentSubCategory }) => {
  const router = useRouter()

  const { lang } = useTranslation('common')
  const locale = lang?.toUpperCase() || 'ME'

  const isVehicleParts = router?.pathname === '/vehicleParts'
  const vehiclePartGroup = isVehicleParts && (router?.query?.group || 'svi-djelovi')

  const menuItems = getMenuItems({ currentGroup, groups, groupCategories, currentSubCategory, router, isVehicleParts, vehiclePartGroup, locale })

  return (
    <React.Fragment>
      <section className='category-horizontal-menu-mobile'>
        <Sausages />

        {Array.isArray(menuItems) ? (
          <div className='category-horizontal-menu-mobile-items'>
            {!isVehicleParts && (!currentGroup || (currentGroup && currentSubCategory && currentGroup.seo === currentSubCategory.seo)) && process.env.NEXT_PUBLIC_SHOW_WEB_SHOP === '1' ? (
              <PartsItem key='parts-item' />
            ) : null}
            {menuItems.map(item => <Item key={item.id} {...item} />)}
          </div>
        ) : null}
      </section>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const groups = getGroups(state)

  return {
    groups: getGroups(state),
    currentGroup: getCurrentGroup(state),
    currentSubCategory: getCurrentSubCategory(state),
    groupCategories: getGroupCategories(state),
  }
}

Container.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    icon: PropTypes.string,
    seo: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  })).isRequired,
  currentGroup: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    seo: PropTypes.string
  }),
  currentSubCategory: PropTypes.shape({
    id: PropTypes.number.isRequired,
    seo: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,
  groupCategories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    group: PropTypes.number.isRequired,
    seo: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  })).isRequired
}

Container.defaultProps = {
  groups: []
}

Container.displayName = 'GroupHorizontalMenuMobile'

export default connect(mapStateToProps)(Container)
