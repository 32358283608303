import 'isomorphic-fetch'
import { TypesHelper } from '../../utils'
import { AuthError, ResponseError } from '../errors'
import { getCurrentLocale } from 'SRC/modules/common/functions'

export class BaseApi {
  static methods = {GET: 'GET', POST: 'POST'}
  static defaultErrorMessage = 'Poštovani, vaš zahtev trenutno ne može biti obrađen zbog tehničkih problema. Ako se ova greška ponovi više puta, molimo pozovite nas.'
  static responseTypes = {
    ERROR: 'error',
    OK: 'ok'
  }

  static async get (url, body, apiHost = process.env.NEXT_PUBLIC_API_HOST) {
    return BaseApi.makeRequest({method: BaseApi.methods.GET, url, body, apiHost})
  };

  static async post (url, body, needStringify = true, apiHost = process.env.NEXT_PUBLIC_API_HOST) {
    return BaseApi.makeRequest({method: BaseApi.methods.POST, url, body, needStringify, apiHost})
  }

  static async makeRequest ({method, url, body, needStringify = true, apiHost = process.env.NEXT_PUBLIC_API_HOST}) {
    const options = {method}
    if (TypesHelper.isNotEmptyString(url)) {
      try {
        let response

        if (method === BaseApi.methods.GET) {
          const bodyString = BaseApi.transformObjectToUrlString(body)
          // eslint-disable-next-line no-undef

          response = await fetch(`${apiHost}${url}${bodyString}`, options)
        } else {

          // send locale param
          if (typeof window !== 'undefined') {
            const locale = getCurrentLocale()

            if (typeof body === 'undefined') body = {}
            if (locale) body.locale = locale

            if (body instanceof FormData) body.append('locale', locale)
          }

          options.body = needStringify ? JSON.stringify(body) : body

          response = await fetch(`${apiHost}${url}`, options)
        }

        const result = {
          status: response.status,
          statusText: response.statusText,
          url: response.url,
          ok: response.ok
        }

        if (result.status === 438) {
          if (typeof window !== 'undefined') {
            const location = window.location
            window.location.href = `${location.protocol}//${location.host}/under-construction`
          }

          return result
        }


        if (result.status === 401 || result.status === 403) {
          const body = await response.json()
          throw new AuthError(result.status, body?.errorInfo?.displayMessage || '')
        } else if (result.status !== 200) {
          const body = await response.json()
          throw new ResponseError(body?.errorInfo?.displayMessage || body.error || 'Došlo je do greške')
        } else {
          result.body = await response.json()
          return result
        }
      } catch (err) {
        throw new ResponseError(err?.message || 'Došlo je do greške')
      }
    } else {
      throw new Error('Empty URL')
    }
  }

  static transformObjectToUrlString = object => {
    let urlParams = ''
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        if (urlParams === '') {
          urlParams += '?'
        } else {
          urlParams += '&'
        }
        urlParams += `${key}=${object[key]}`
      }
    }
    return urlParams
  }
}
