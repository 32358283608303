import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from 'next-translate/useTranslation'
import { debounce } from 'throttle-debounce'
import { Input } from '../Input'

export const Container = ({
  id,
  onValueFromChange,
  onValueToChange,
  valueTo,
  valueFrom,
  title,
  mask,
  measure,
  show,
  isPrice,
  allowedCharacters
}) => {
  const { t } = useTranslation('common')

  React.useEffect(() => {
    if ((Number(valueFrom) > Number(valueTo) && Number(valueTo) !== -1 && Number(valueFrom) !== -1)) {
      onValueFromChange(valueTo)
    }
  }, [valueFrom, valueTo])

  const onFromAccept = (value) => {
    onValueFromChange(value)
  }

  const onToAccept = (value) => {
    onValueToChange(value)
  }

  const inputFromKey = `${id}-from`
  const inputToKey = `${id}-to`

  return show ? (
    <React.Fragment key={`${id}-input-range`}>
      <label>{title}</label>
      <Input
        key={inputFromKey}
        inputId={inputFromKey}
        mask={mask}
        isPrice={isPrice}
        measure={measure}
        value={valueFrom}
        onAccept={onFromAccept}
        placeholder={t('Od')}
        className='polja-pretrage-item__input-range polja-pretrage-item__input-range_from'
        allowedCharacters={allowedCharacters}
      />

      <Input
        key={inputToKey}
        inputId={inputToKey}
        mask={mask}
        isPrice={isPrice}
        value={valueTo}
        measure={measure}
        onAccept={onToAccept}
        placeholder={t('Do')}
        className='polja-pretrage-item__input-range polja-pretrage-item__input-range_to'
        allowedCharacters={allowedCharacters}
      />
    </React.Fragment>
  ) : null
}

Container.displayName = 'InputRange'

Container.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  valueFrom: PropTypes.any,
  valueTo: PropTypes.any,
  allowedCharacters: PropTypes.array,
  show: PropTypes.bool,
  isPrice: PropTypes.bool,
  measure: PropTypes.string,
  mask: PropTypes.shape({
    mask: PropTypes.any.required
  }),
  onValueFromChange: PropTypes.func,
  onValueToChange: PropTypes.func
}

Container.defaultProps = {
  show: true
}

const InputRange = React.memo(Container, (prevProps, nextProps) => {
  if (prevProps.valueFrom === nextProps.valueFrom &&
    prevProps.valueTo === nextProps.valueTo &&
    prevProps.title === nextProps.title
  ) {
    return true
  }
})

export default InputRange
