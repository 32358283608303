import initialState from 'SRC/modules/redux/initialState'
import actionTypes from 'SRC/modules/redux/actionTypes'

export const vehiclePartsListReducer = (state = initialState.vehicleParts.list, action) => {
  switch (action.type) {
    case actionTypes.SET_VEHICLE_PARTS:
      return {...state, products: action.payload}
    case actionTypes.SET_VEHICLE_PARTS_LOADING:
      return {...state, isLoading: action.payload}
    case actionTypes.SET_VEHICLE_PARTS_PAGINATION:
      return {...state, pagination: action.payload}
    case actionTypes.SET_VEHICLE_PARTS_ERROR:
      return {...state, error: action.payload}
    default:
      return state
  }
}
