import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getGroups, getCurrentGroup } from 'SRC/modules/groups/selectors'
import { Item } from '../Item'
import { PartsItem } from '../PartsItem'

const ListWithNewParts = ({ groups, currentGroup }) => {
  console.log('process.env.NEXT_PUBLIC_SHOW_WEB_SHOP', process.env.NEXT_PUBLIC_SHOW_WEB_SHOP)
  return (
    <div className='category-items'>
      {groups && Array.isArray(groups) ? groups.map(item => {
        return (
          <Item
            {...item}
            currentGroup={currentGroup}
            key={item.id}
          />
        )
      }) : null}
      {process.env.NEXT_PUBLIC_SHOW_WEB_SHOP === '1' ? <PartsItem /> : null}
    </div>
  )
}

const mapStateToProps = (state) => {
  const groups = getGroups(state)

  return {
    groups: getGroups(state),
    currentGroup: getCurrentGroup(state)
  }
}

ListWithNewParts.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    icon: PropTypes.string,
    seo: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  })).isRequired,
  currentGroup: PropTypes.shape({
    categories: PropTypes.array,
    seo: PropTypes.string
  })
}

ListWithNewParts.defaultProps = {
  groups: []
}

export default connect(mapStateToProps)(ListWithNewParts)
