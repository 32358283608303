import { adsTypes, globalOptions } from 'Core/constants'
import { CookiesHelper } from 'Core/cookiesHelper/CookiesHelper'

const cookies = new CookiesHelper()
const isAuthorized = Boolean(cookies.get('token') && cookies.get('userId'))
const initialState = {
  user: {
    auth: {
      isAuthorized: isAuthorized,
      isLoading: false,
      formErrors: {
        login: '',
        loginInPopup: ''
      },
      error: null
    },
    registration: {
      currentUserType: null,
      isLoading: false,
      smsCodeWasSent: false,
      activeTip: globalOptions.defaultTip,
      tempLogo: null,
      logo: null
    },
    profile: {
      currentPage: null,
      notifications: [],
      info: null,
      newLogo: null,
      newBanner: null,
      error: null,
      isLoading: false,
      packageInactiveMessage: '',
      photos: [],
      ads: {
        query: null,
        error: null
      },
      promo: {
        ad: null,
        error: null
      },
      favouriteAds: {
        Published: {
          isLoading: false,
          elements: []
        },
        Expired: {
          isLoading: false,
          elements: []
        },
        Sold: {
          isLoading: false,
          elements: []
        },
        fetchedInitially: false
      },
      favouriteProfiles: {
        isLoading: false,
        fetchedInitially: false
      },
      savedFilters: {
        isLoading: false,
        elements: []
      },
      pagination: {
        params: {
          currentPage: 1,
          countPerPage: 10,
          count: 0
        }
      },
      addons: {
        elements: [],
        isLoading: false
      },
      discountCard: {
        card: null,
        isLoading: false,
        code: ''
      },
      priceTypes: null
    },
    packages: {
      items: [],
      packageCategories: {
        isLoading: false,
        items: []
      },
      discounts: [],
      selectedPackage: null
    },
    list: {
      query: null,
      elements: [],
      isLoading: false,
      isAdditionalLoading: false,
      userType: null,
      pagination: {
        page: 1,
        countPerPage: globalOptions.usersCountPerPage,
        totalCount: 0
      },
      lastChangedField: null
    },
    listSidebar: {
      isLoading: false,
      elements: {}
    },
    detail: {
      info: null
    }
  },
  categories: {
    elements: [],
    groupCategories: [],
    bySeo: {},
    currentSubcategory: null,
    isCategoryPage: false,
    specifications: [],
    activeCategories: null,
    similarWords: null
  },
  groups: {
    elements: [],
    currentGroup: null,
    isGroupPage: false
  },
  ads: {
    list: {
      elements: [],
      isLoading: false,
      paid: [],
      isSavedAdsButtonEnabled: true,
      paidRequested: false
    },
    detail: {
      currentAd: null,
      reportTypes: [],
      isServer: true,
      similarAds: {
        isLoading: false,
        elements: []
      },
      registrationFormulas: [],
      registrationFormulaDetails: null,
      registrationPremiumClasses: []
    },
    filter: {
      values: null,
      needResetState: false,
      dependent: null
    },
    pagination: {
      params: {
        currentPage: 1,
        countPerPage: globalOptions.adsCountPerPage,
        adsType: adsTypes.paid,
        paidCount: 0,
        freeCount: 0,
        totalCount: 0
      }
    },
    brands: {
      elements: []
    },
    products: {
      elements: [],
      isLoading: false
    },
    equipment: {
      elements: []
    },
    add: {
      isLoading: false,
      result: null,
      packages: [],
      activeTab: 'register',
      wasSentSms: false,
      needToResetFormValues: true
    },
    listings: [],
    services: {
      elements: []
    },
    serviceTypes: {
      elements: [],
      isLoading: false
    },
    edit: {
      ad: null,
      error: null,
      success: null
    },
    shared: {
      photos: [],
      resetPhotos: false,
      photosUploading: false
    }
  },
  common: {
    isPopupLoginBlockOpened: false,
    is404: false,
    transitions: {
      isLoading: false,
      internalTransitionFunc: null,
      isTransitioning: false
    },
    userTypes: [],
    form: {
      fields: null,
      result: null,
      error: null,
      isLoading: false,
      smsCodeVerified: false,
      wasSmsSent: false
    },
    isMobileDevice: false,
    pageMetaTags: [],
    pageMetaTagsSingle: null
  },
  geoObjects: {
    countries: {
      isLoading: false,
      elements: []
    },
    regions: {
      elements: [],
      byCountry: {},
      isLoading: false
    },
    cities: {
      elements: [],
      byCountry: {},
      byCountryWithinRegions: {},
      isLoading: false
    },
    locations: {
      elements: [],
      byCity: {},
      isLoading: false
    }
  },
  garage: {
    add: {
      isLoading: false,
      result: null,
      fields: {
        category: null,
        brand: null,
        product: null,
        specification: null
      },
      error: null
    },
    edit: {
      item: null,
      error: null,
      success: null
    },
    list: {
      elements: [],
      isLoading: false,
      page: 0
    },
    shared: {
      photo: null,
      categories: []
    }
  },
  discountCard: {
    partners: {
      list: {
        elements: [],
        pagination: {
          page: 1,
          limit: 10,
          count: 0
        },
        isLoading: false,
        categories: []
      },
      detail: null
    },
    verification: {
      verified: false,
      status: -1
    }
  },
  vehicleParts: {
    list: {
      products: [],
      isLoading: false,
      pagination: {
        params: {
          page: 1,
          totalCount: 0,
          totalPages: 0
        }
      },
      error: ''
    },
    filter: {
      filteredFields: null,
      isFilteredFieldsLoading: false
    },
    detail: {
      item: null,
      isLoading: false,
      similarProducts: {
        elements: [],
        isLoading: false
      }
    }
  },
}

export default initialState
