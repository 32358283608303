import React from 'react'
import PropTypes from 'prop-types'
import { Phone } from '../UserContacts/Phone'
import { BusinessAddresses } from '../UserContacts/Business/Addresses'

export const UserContactsBasic = ({ user, adStatus }) => {
  const visiblePhones = user.phones.filter(phone => {
    if (phone.hasOwnProperty('visible')) return phone.visible
    return true
  })
  return [
    <div className='sidebar-user-contact' key='phones'>
      {!adStatus || adStatus !== 'Sold' ? visiblePhones.map(phone => <Phone phone={phone} key={phone.phone} />) : null}
    </div>,
    <BusinessAddresses key='addresses' addresses={user.addresses} renderWorkingTime={false} />
  ]
}

UserContactsBasic.propTypes = {
  adStatus: PropTypes.string,
  user: PropTypes.shape({
    phones: PropTypes.arrayOf(PropTypes.shape({phone: PropTypes.string.isRequired})).isRequired,
    addresses: PropTypes.array
  }).isRequired
}

UserContactsBasic.displayName = 'UserContactsBasic'
