
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import '../public/scss/app.scss';
import { registerServiceWorker } from '../sw';
import React from 'react';
import { Provider } from 'react-redux';
import App from 'next/app';
import withRedux from 'next-redux-wrapper';
import setLanguage from 'next-translate/setLanguage';
import { makeStore } from '../src/modules/redux/store';
import { Layout, Head } from '../src/modules/layout';
import { BrowserNotSupported } from '../src/modules/staticInfo';
import { fetchGroups } from '../src/modules/groups/actions';
import { fetchGarageCategories } from '../src/modules/garage/shared/actions';
import { fetchUserInfo, fetchPriceTypes } from '../src/modules/users/Profile/actions';
import { CookiesHelper } from '../src/core/cookiesHelper/CookiesHelper';
import { StorageHelper } from '../src/core/storageHelper/StorageHelper';
import Router from 'next/router';
import config from 'SRC/config/config.json';
import { setAppLoading, setIsTransitioning, setAppState, fetchPageMetaTags, setUserTypes, setIsMobileDevice, fetchUserTypes } from '../src/modules/common/actions';
import { userTypes as userTypesData } from '../src/modules/common/data/userTypes';
import { isBrowserSupported, flattenWithChildren } from '../src/utils';
import { getAppInternalTransitionFunc } from '../src/modules/common/selectors';
import LocaleContext from '../src/contexts/LocaleContext';
class MyApp extends App {
    constructor(props) {
        super(props);
        this.routes = [];
        if (Router.router) {
            Router.router.events.on('routeChangeStart', this.onRouteChangeStartHandler);
            Router.router.events.on('routeChangeComplete', this.onRouteChangeCompleteHandler);
            Router.router.events.on('routeChangeError', this.onRouteChangeErrorHandler);
        }
    }
    async componentDidMount() {
        // process.env.NEXT_PUBLIC_NAME !== 'development' && registerServiceWorker()
        if (!Router.router.defaultLocale)
            Router.router.defaultLocale = 'me';
    }
    onRouteChangeStartHandler = async (url) => {
        if (this.props) {
            const { store } = this.props;
            if (store) {
                const appState = store.getState();
                const func = getAppInternalTransitionFunc(appState);
                const isInternalTransition = func && func(url);
                if (!isInternalTransition) {
                    store.dispatch(setAppLoading(true));
                }
                store.dispatch(setIsTransitioning(true));
            }
        }
        this.pushCurrentRouteInfo();
    };
    onRouteChangeCompleteHandler = async (url) => {
        if (this.props) {
            const { store } = this.props;
            if (store) {
                store.dispatch(setAppLoading(false));
                const appState = store.getState();
                const func = getAppInternalTransitionFunc(appState);
                const isInternalTransition = func && func(url);
                if (!isInternalTransition) {
                    // window.scrollTo(0, 0)
                }
                store.dispatch(setIsTransitioning(false));
            }
        }
        this.fixScrollPosition();
    };
    onRouteChangeErrorHandler = async () => {
        if (this.props) {
            const { store } = this.props;
            if (store) {
                store.dispatch(setAppLoading(false));
            }
        }
    };
    isBack = () => {
        return this.routes.length >= 2 && Router.pathname === this.routes[this.routes.length - 2].pathname;
    };
    fixScrollPosition = () => {
        let scrollY = 0;
        if (this.routes.length >= 2) {
            const lastRoute = this.routes[this.routes.length - 1];
            const beforeLastRoute = this.routes[this.routes.length - 2];
            const adListRoutes = ['/', '/category', '/search', '/user'];
            if (lastRoute && beforeLastRoute) {
                const lastRouteContainsAds = adListRoutes.includes(lastRoute.pathname);
                const beforeLastRouteContainsAds = adListRoutes.includes(beforeLastRoute.pathname);
                if (lastRouteContainsAds && beforeLastRouteContainsAds) {
                    this.routes = [];
                }
            }
        }
        if (this.isBack()) {
            this.routes.pop(); // route where we come from
            const targetRoute = this.routes.pop(); // route where we return
            scrollY = targetRoute.scrollY; // scrollY we had before
        }
        window.requestAnimationFrame(() => window.scrollTo(0, scrollY));
    };
    pushCurrentRouteInfo = () => {
        this.routes.push({ pathname: Router.pathname, scrollY: window.scrollY });
    };
    static async getInitialProps({ Component, router, ctx }) {
        const state = ctx.store.getState();
        const { route, asPath, pathname, query } = router;
        const cookies = new CookiesHelper();
        let isTokenExisted, id, token, renderBrowserNotSupported, iOSDeviceDetected;
        if (ctx.isServer) {
            if (!query?.locale || query?.locale === 'me') {
                ctx.store.dispatch(setUserTypes(userTypesData));
                router.locale = 'me';
            }
            else {
                await ctx.store.dispatch(fetchUserTypes({ locale: query.locale?.toUpperCase() || 'ME' }));
                router.locale = query?.locale || 'me';
            }
            await ctx.store.dispatch(fetchGroups({ locale: query.locale?.toUpperCase() || 'ME' }));
            if (ctx.pathname !== '/') {
                if (ctx.pathname === '/addToGarage') {
                    await ctx.store.dispatch(fetchGarageCategories());
                }
                isTokenExisted = Boolean(ctx.req.cookies && ctx.req.cookies.token && ctx.req.cookies.userId);
                id = ctx.req.cookies.userId;
                token = ctx.req.cookies.token;
                if (ctx.req.params && ctx.req.params.userId && ctx.req.params.token) {
                    isTokenExisted = true;
                    id = ctx.req.params.userId;
                    token = ctx.req.params.token;
                }
            }
            if (ctx.req.browser) {
                renderBrowserNotSupported = !isBrowserSupported(ctx.req.browser);
            }
            if (ctx.req.isMobile) {
                const mobileOS = ctx.req.mobileOS && (ctx.req.mobileOS.startsWith('iOS') || ctx.req.mobileOS.startsWith('OS X'))
                    ? 'iOS'
                    : 'Android';
                ctx.store.dispatch(setIsMobileDevice(mobileOS));
            }
            else {
                ctx.store.dispatch(setIsMobileDevice(false));
            }
        }
        else {
            if (ctx.pathname !== '/') {
                if (ctx.pathname === '/addToGarage') {
                    await ctx.store.dispatch(fetchGarageCategories());
                }
            }
            isTokenExisted = Boolean(cookies.get('token') && cookies.get('userId'));
            id = cookies.get('userId');
            token = cookies.get('token');
        }
        await ctx.store.dispatch(fetchPageMetaTags({}, query.locale?.toUpperCase() || 'ME'));
        if (isTokenExisted) {
            const needCountActiveAds = ctx.pathname === '/profile' || ctx.pathname === '/editAd';
            await ctx.store.dispatch(fetchUserInfo({ userId: id, token, countActiveAds: needCountActiveAds, locale: query?.locale?.toUpperCase() || 'ME' }));
            await ctx.store.dispatch(fetchPriceTypes(id, token));
        }
        const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};
        pageProps.renderBrowserNotSupported = renderBrowserNotSupported;
        if (renderBrowserNotSupported)
            pageProps.title = `Browser is not supported | ${config.siteName}`;
        if (iOSDeviceDetected)
            pageProps.iOSDeviceDetected = iOSDeviceDetected;
        pageProps.locale = query?.locale || router?.locale || 'me';
        const isTransitioning = ctx.store.getState().common.transitions.isTransitioning;
        if (isTransitioning)
            ctx.store.dispatch(setAppState(pageProps.state));
        // ctx.store.dispatch(setAppState(pageProps.state))
        return { pageProps };
    }
    App = pageProps => (<div className='container__app'>
      <Head title={pageProps.title}/>
      <main className='forApp'>
        {pageProps.children}
      </main>
    </div>);
    renderLayout = (pageProps, Component) => {
        const App = this.App;
        const appComponents = ['termsofserviceapp', 'vehicleregistrationoldapp', 'privacypolicyapp',
            'aboutdiscountcardapp', 'termsofservicediscountcardapp', 'registrationcalculatorapp',
            'discountcardactivationapp', 'becomeapartnerapp', 'partsrequestapp', 'activatepromockbapp', 'ckbnoticeapp',
            'consumerrightsapp', 'secureshoppingapp', 'helpapp'
        ];
        const isForApp = appComponents.includes(pageProps.component);
        if (pageProps.renderBrowserNotSupported) {
            return <React.Fragment>
        <Head title={pageProps.title}/>
        <BrowserNotSupported />
      </React.Fragment>;
        }
        if (pageProps.underConstruction) {
            return <Component />;
        }
        if (pageProps.component && isForApp) {
            return <App {...pageProps}><Component {...pageProps} isForApp={isForApp}/></App>;
        }
        return (<Layout isForApp={isForApp} title={pageProps.title} pageUrlItems={pageProps.pageUrlItems || null} isUserSearch={pageProps.isUserSearch || false}>
        <Component {...pageProps}/>
      </Layout>);
    };
    render() {
        const { Component, pageProps, store } = this.props;
        const locale = pageProps?.locale || 'me';
        return (<React.Fragment>
        <noscript><iframe src={`https://www.googletagmanager.com/ns.html?id=${config.head.gaID}`} height='0' width='0' style={{ display: 'none', visibility: 'hidden' }}/></noscript>
        <Provider store={store}>
          <LocaleContext.Provider value={{ locale }}>
            {this.renderLayout(pageProps, Component)}
          </LocaleContext.Provider>
        </Provider>
      </React.Fragment>);
    }
}
const __Next_Translate__Page__195f1a5e167__ = withRedux(makeStore)(MyApp);

    export default __appWithI18n(__Next_Translate__Page__195f1a5e167__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      
    });
  