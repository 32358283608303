import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactSVG from 'react-svg'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { groupMenuItems } from 'SRC/modules/vehicleParts/utils/vehiclePartsUtils'
import { getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { getCurrentGroup } from 'SRC/modules/groups/selectors'
import Link from 'next/link'

import translations from '../../../../../../public/data/translations.json'

const svgStyle = 'width: 1.25rem; height: 1.25rem; display: flex; align-items: center; opacity: 0.6;'

const Sausages = ({ group, subCategory }) => {
  const { lang } = useTranslation('common')
  const locale = lang?.toUpperCase() || 'ME'
  const router = useRouter()

  const isVehicleParts = router?.pathname === '/vehicleParts'

  if (isVehicleParts) {
    const partsItem = groupMenuItems.find(item => item.seo === router?.query?.category || item.seo === router?.query?.[0])

    return (
      <section className='sausage-buttons'>
        <div key='sausage-group' className='sausage-button'>
          <p>{translations.noviDjeloviTitle[locale]}</p>

          <Link href='/' as='/'>
            <a className='sausage-button-close'>
              <ReactSVG
                src={'/icons/close.svg'}
                beforeInjection={svg => {
                  svg.setAttribute('style', svgStyle)
                }}
              />
            </a>
          </Link>
        </div>

        {partsItem ? (
          <div key='sausage-subcategory' className='sausage-button'>
            <p>{partsItem.title}</p>

            <Link
              href={{ pathname: '/vehicleParts' }}
              as={`/${translations.noviDjeloviUrl[locale]}`}
            >
              <a className='sausage-button-close'>
                <ReactSVG
                  src={'/icons/close.svg'}
                  beforeInjection={svg => {
                    svg.setAttribute('style', svgStyle)
                  }}
                />
              </a>
            </Link>
          </div>
        ) : null}
      </section>
    )
  }

  return group ? (
    <section className='sausage-buttons'>
      <div key='sausage-group' className='sausage-button'>
        <p>{group.title}</p>

        <Link href='/' as='/'>
          <a className='sausage-button-close'>
            <ReactSVG
              src={'/icons/close.svg'}
              beforeInjection={svg => {
                svg.setAttribute('style', svgStyle)
              }}
            />
          </a>
        </Link>
      </div>

      {subCategory && group.seo !== subCategory.seo ? (
        <div key='sausage-subcategory' className='sausage-button'>
          <p>{subCategory.title}</p>

          <Link
            href={{ pathname: '/category', query: { category: group.seo } }}
            as={`/${group.seo}`}
          >
            <a className='sausage-button-close'>
              <ReactSVG
                src={'/icons/close.svg'}
                beforeInjection={svg => {
                  svg.setAttribute('style', svgStyle)
                }}
              />
            </a>
          </Link>
        </div>
      ) : null}
    </section>
  ) : null
}

const mapStateToProps = state => {
  return {
    group: getCurrentGroup(state),
    subCategory: getCurrentSubCategory(state)
  }
}

Sausages.propTypes = {
  group: PropTypes.object.isRequired,
  subCategory: PropTypes.shape({
    id: PropTypes.number.isRequired,
    seo: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired
}

export default connect(mapStateToProps)(Sausages)
