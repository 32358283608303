import React from 'react'
import { connect } from 'react-redux'
import { getIsMobileDevice, getIsTransitioning } from 'SRC/modules/common/selectors'
import Link from 'next/link'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import useTranslation from 'next-translate/useTranslation'
import ReactSVG from 'react-svg'

import translations from '../../../../../../public/data/translations.json'

const PartsItem = ({
  id,
  icon,
  title,
  isMobileDevice,
  isTransitioning
}) => {
  const { lang } = useTranslation('common')
  const router = useRouter()

  console.log('PartsItem router', router)

  const locale = lang?.toUpperCase() || 'ME'

  const flexGrow = 3

  const isActive = router?.pathname === '/vehicleParts'

  if (!isTransitioning || isActive) {
    return (
      <div
        className={`kategorije-item ${isActive ? 'active' : ''}`}
        style={!isMobileDevice ? { flexGrow } : {}}
      >
        <Link href={'/vehicleParts'} as={`/${translations.noviDjeloviUrl[locale]}`}>
          <a>
            <div className='kategorije-item-icon'>
              <ReactSVG src='/icons/ico-novi-djelovi.svg' beforeInjection={svg => {
                svg.setAttribute('style', 'width: 50px; height: auto;')
              }} wrapper='span' className='kategorije-item-icon__parts' />
            </div>
            <div className='kategorije-item-text kategorije-item-text--parts'>
              <p>{translations.noviDjeloviTitle[locale]}</p>
            </div>
          </a>
        </Link>
      </div>
    )
  }

  return (
    <div
      className={`kategorije-item disabled`}
      style={!isMobileDevice ? { flexGrow } : {}}
    >
      <a href='javascript:void(0)' style={{ opacity: .4, cursor: 'not-allowed' }}>
        <div className='kategorije-item-icon'>
          <img className='kategorije-item-icon__parts' src='/icons/ico-novi-djelovi.svg' alt='Novi djelovi' />
        </div>
        <div className='kategorije-item-text kategorije-item-text--parts'>
          <p>{translations.noviDjeloviTitle[locale]}</p>
        </div>
      </a>
    </div>
  )
}

const mapStateToProps = state => ({
  isMobileDevice: getIsMobileDevice(state),
  isTransitioning: getIsTransitioning(state)
})

PartsItem.propTypes = {
  isMobileDevice: PropTypes.bool.isRequired,
  isTransitioning: PropTypes.bool.isRequired
}

export default connect(mapStateToProps)(PartsItem)
