import PropTypes from 'prop-types'
import React from 'react'
import { getInfo } from 'SRC/modules/users/detail/selectors'
import { connect } from 'react-redux'
import { getIsMobileDevice } from 'SRC/modules/common/selectors'
import { userStatuses } from 'Core/constants'
import { getUserTypes } from 'SRC/modules/common/selectors'
import { isUserOnline } from 'SRC/utils'
import { FloatingBanner } from 'SRC/modules/common/components/FloatingBanner'
import config from 'SRC/config/config.json'
import { SocServices, Header, UserCard, UserContactsBasic } from '../../../shared/components'

export class Container extends React.PureComponent {
  getUserTypeSeo = () => {
    const { info } = this.props
    return info && info.type ? info.type.seo : ''
  }

  getSidebarTitle = () => {
    let title = ''

    if (this.props.info && this.props.info.type && this.props.info.type.seo && Array.isArray(this.props.userTypes)) {
      const searchedUserType = this.props.userTypes.filter(item => item.seo === this.props.info.type.seo)
      if (searchedUserType.length) title = searchedUserType[0].name
    }

    return title
  }

  getUserCardInfo = () => {
    const isBusinessUser = this.isBusinessUser()
    const {info} = this.props
    const mainAddress = info && info.userAddresses && this.getMainAddress(info.userAddresses)
    const arLogo = info && Array.isArray(info.media) && info.media.filter(item => item.title === 'profile-picture')
    return info
      ? {
        name: info.name,
        login: info.username,
        status: isUserOnline(info.active) ? userStatuses.online : userStatuses.offline,
        isEmailVerified: Boolean(info.email && info.email.verified),
        isPhoneVerified: true,
        isAddressVerified: false,
        gender: info.gender,
        type: isBusinessUser ? 'business' : 'free',
        image: isBusinessUser && arLogo.length ? arLogo[0] : null,
        addressCount: isBusinessUser ? info.userAddresses.length : 0,
        activeAddons: info.activeAddons && info.activeAddons.length ? info.activeAddons : null,
        city: isBusinessUser ? mainAddress && mainAddress.city ? mainAddress.city.name : null : null,
        isPro: false
      }
      : null
  }

  getUserContactsInfo = () => {
    return this.props.info
      ? {
        type: this.isBusinessUser() ? 'business' : 'free',
        addresses: this.props.info.userAddresses,
        phones: this.props.info.phones,
        login: this.props.info.username,
        seo: this.props.info.type && this.props.info.type.seo
      }
      : null
  }

  isBusinessUser = () => {
    let isBusiness = false
    const seo = this.getUserTypeSeo()

    const businessUserTypes = [...config.userTypes.businessUsersSeos, config.userTypes.serviceProviderSeo]
    if (businessUserTypes.includes(seo)) isBusiness = true

    return isBusiness
  }

  getMainAddress = addresses => {
    if (Array.isArray(addresses)) {
      const searchedAddress = addresses.filter(address => address.main)
      if (searchedAddress.length) {
        return searchedAddress[0]
      } else {
        return null
      }
    }
  }

  render () {
    const { isMobileDevice } = this.props

    const userCardInfo = this.getUserCardInfo()
    const userContactsInfo = this.getUserContactsInfo()
    const title = this.getSidebarTitle()

    return (
      <aside className='sidebar'>
        <div className='sidebar-prodavac'>
          {title && <Header title={title} />}
          <div className={`sidebar-content ${this.isBusinessUser() ? `sidebar-user-prodavnica` : `sidebar-user-free`}`}>
            <div className='sidebar-user'>
              {userCardInfo && <UserCard user={userCardInfo} />}
              {userContactsInfo && <UserContactsBasic user={userContactsInfo} />}
            </div>
          </div>
        </div>

        <SocServices />
      </aside>
    )
  }
}

const mapStateToProps = state => ({
  info: getInfo(state),
  userTypes: getUserTypes(state),
  isMobileDevice: getIsMobileDevice(state)
})

Container.displayName = 'VehiclPartsSidebar'

Container.propTypes = {
  info: PropTypes.any,
  userTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    seo: PropTypes.string,
    name: PropTypes.string
  })),
  isMobileDevice: PropTypes.bool.isRequired
}

export default connect(mapStateToProps)(Container)
