export const groupMenuItems = [
	{
		id: 1,
		seo: 'svi-djelovi',
		title: 'Svi djelovi',
		iconSvg: 'shop/svi-djelovi.svg'
	},
	{
		id: 2,
		seo: 'gume',
		title: 'Gume',
		iconSvg: 'shop/gume.svg'
	},
	{
		id: 3,
		seo: 'akumulatori',
		title: 'Akumulatori',
		iconSvg: 'shop/akumulatori.svg'
	},
	{
		id: 4,
		seo: 'ulja-i-maziva',
		title: 'Ulja i maziva',
		iconSvg: 'shop/ulja-i-maziva.svg'
	},
	{
		id: 5,
		seo: 'filteri',
		title: 'Filteri',
		iconSvg: 'shop/filteri.svg'
	},
	{
		id: 6,
		seo: 'karoserija',
		title: 'Karoserija',
		iconSvg: 'shop/karoserija.svg'
	},
	{
		id: 7,
		seo: 'univerzalno',
		title: 'Univerzalno',
		iconSvg: 'shop/univerzalno.svg'
	},
	{
		id: 8,
		seo: 'alati-i-oprema',
		title: 'Alati i oprema',
		iconSvg: 'shop/alati-i-oprema.svg'
	},
]

export const urlPartsDefault = {
	'grupa': {
		key: 'grupa',
		position: 1
	},
	'kategorija': {
		key: 'kategorija',
		position: 2
	},
	'proizvodjac': {
		key: 'brend_automobila',
		position: 3
	},
	'model': {
		key: 'model_automobila',
		position: 4
	},
	'godina': {
		key: 'godina',
		position: 5
	},
	'tip-vozila': {
		key: 'tip_vozila',
		position: 6
	},
	'cijena': {
		key: 'sortByPrice',
		position: 7
	},
	'strana': {
		key: 'page',
		position: 8
	},
}

export const urlPartsGume = {
	'sirina': {
		key: 'sirina',
		position: 1
	},
	'visina': {
		key: 'visina',
		position: 2
	},
	'promjer': {
		key: 'promjer',
		position: 3
	},
	'sezona': {
		key: 'sezona',
		position: 4
	},
	'tip-vozila': {
		key: 'tip_vozila_gume',
		position: 5
	},
	'proizvodjac': {
		key: 'proizvodjac',
		position: 6
	},
	'cijena': {
		key: 'sortByPrice',
		position: 7
	},
	'strana': {
		key: 'page',
		position: 8
	},
}

export const urlPartsAkumulatori = {
	napon: {
		key: 'napon',
		position: 4
	},
	kapacitet: {
		key: 'kapacitet',
		position: 5
	},
	sirina: {
		key: 'sirina',
		position: 1
	},
	visina: {
		key: 'visina',
		position: 2
	},
	duzina: {
		key: 'duzina',
		position: 3
	},
	proizvodjac: {
		key: 'proizvodjac',
		position: 6
	},
	'cijena': {
		key: 'sortByPrice',
		position: 7
	},
	'strana': {
		key: 'page',
		position: 8
	},
}

export const urlPartsOstalo = {
	kategorija: {
		key: 'kategorija',
		position: 1
	},
	proizvodjac: {
		key: 'proizvodjac',
		position: 2
	},
	'cijena': {
		key: 'sortByPrice',
		position: 3
	},
	'strana': {
		key: 'page',
		position: 4
	},
}

export const getShouldFetchVehicleParts = ({ group, urlValues, filteredFields }) => {
	if (group === 'svi-djelovi') {
		return urlValues && Object.keys(urlPartsDefault).filter((segmentKey) => segmentKey !== 'strana' && segmentKey !== 'model' && segmentKey !== 'cijena').every((segmentKey) => {
			return urlValues[urlPartsDefault[segmentKey].key]
		})
	}

	return true
}

export const getUrlValues = ({ group, urlParts }) => {
	if (!group || group === 'svi-djelovi') {
		const values = { page: 1, grupa: '', kategorija: '', brend_automobila: '', model_automobila: '', godina: '', tip_vozila: '', sortByPrice: '' };

			(urlParts || []).forEach((segment) => {
				Object.keys(urlPartsDefault).forEach((key) => {
					if (segment.includes(key)) {
						values[urlPartsDefault[key].key] = segment.replace(`${key}-`, '')
					}
				})

			})

		return values
	}

	if (group === 'gume') {
		const values = { page: 1, sirina: '', visina: '', promjer: '', sezona: '', tip_vozila_gume: '', proizvodjac: '', sortByPrice: '' };

		(urlParts || []).forEach((segment) => {
			Object.keys(urlPartsGume).forEach((key) => {
				if (segment.includes(key)) {
					values[urlPartsGume[key].key] = segment.replace(`${key}-`, '')
				}
			})
		})

		return values
	}

	if (group === 'akumulatori') {
		const values = { page: 1, sirina: '', visina: '', duzina: '', napon: '', kapacitet: '', proizvodjac: '', sortByPrice: '' };

		(urlParts || []).forEach((segment) => {
			Object.keys(urlPartsAkumulatori).forEach((key) => {
				if (segment.includes(key)) {
					values[urlPartsAkumulatori[key].key] = segment.replace(`${key}-`, '')
				}
			})
		})

		return values
	}

	if (group) {
		const values = { page: 1, kategorija: '', proizvodjac: '', sortByPrice: '' };

		(urlParts || []).forEach((segment) => {
			Object.keys(urlPartsOstalo).forEach((key) => {
				if (segment.includes(key)) {
					values[urlPartsOstalo[key].key] = segment.replace(`${key}-`, '')
				}
			})
		})

		return values
	}
}

export const getUrlParts = (group, values) => {
	if (!group || group === 'svi-djelovi') {
		let parts = Array.from({ length: Object.keys(urlPartsDefault).length }, () => '')

		console.log('getUrlParts values', values)
		Object.keys(urlPartsDefault).forEach((key) => {
			const value = values[urlPartsDefault[key].key]

			console.log('getUrlParts key', key)
			if (value && Number(value) !== -1 && !(key === 'strana' && Number(value) === 1)) {
				// segments.push(`${key}-${value}`)
				parts[urlPartsDefault[key].position - 1] = [key, value, urlPartsDefault[key].key]
			}
		})

		parts = parts.filter((part) => part)

		return parts
	}

	if (group === 'gume') {
		let parts = Array.from({ length: Object.keys(urlPartsGume).length }, () => '')

		Object.keys(urlPartsGume).forEach((key) => {
			const value = values[urlPartsGume[key].key]

			if (value && Number(value) !== -1 && !(key === 'strana' && Number(value) === 1)) {
				parts[urlPartsGume[key].position - 1] = [key, value, urlPartsGume[key].key]
			}
		})

		parts = parts.filter((part) => part)

		return parts
	}

	if (group === 'akumulatori') {
		let parts = Array.from({ length: Object.keys(urlPartsAkumulatori).length }, () => '')

		Object.keys(urlPartsAkumulatori).forEach((key) => {
			const value = values[urlPartsAkumulatori[key].key]

			if (value && Number(value) !== -1 && !(key === 'strana' && Number(value) === 1)) {
				parts[urlPartsAkumulatori[key].position - 1] = [key, value, urlPartsAkumulatori[key].key]
			}
		})

		parts = parts.filter((part) => part)

		return parts
	}

	if (group) {
		let parts = Array.from({ length: Object.keys(urlPartsOstalo).length }, () => '')

		Object.keys(urlPartsOstalo).forEach((key) => {
			const value = values[urlPartsOstalo[key].key]

			if (value && Number(value) !== -1 && !(key === 'strana' && Number(value) === 1)) {
				parts[urlPartsOstalo[key].position - 1] = [key, value, urlPartsOstalo[key].key]
			}
		})

		parts = parts.filter((part) => part)

		return parts
	}
}

export const getUrl = (group, values) => {
	if (!group) {
		console.log('getUrl values', values)

		if (values['text-filter']) {
			return { asUrl: `/novi-djelovi?text-filter=${values['text-filter']}`, href: { pathname: '/vehicleParts', query: { 'text-filter': values['text-filter'] } } }
		}

		return { asUrl: '/novi-djelovi', href: { pathname: '/vehicleParts' } }
	}

	const urlParts = getUrlParts(group, values)?.map(([key, value]) => `${key}-${value}`) || []

	const asUrl = `/novi-djelovi/${group !== 'svi-djelovi' ? group : ''}` + `${urlParts.length ? ('/' + urlParts.join('/')) : ''}`

	const href = {
		pathname: '/vehicleParts',
		query: {
			'0': urlParts.join('/')
		}
	}

	if (group !== 'svi-djelovi') href.query.group = group

	return { asUrl, href }
}

export const getListComponent = (values) => {
	if (!values) return 'grupa'

	if (values.grupa && values.kategorija && values.proizvodjac && values.model && values.godina && values.tipVozila) {
		return 'parts'
	}

	if (values.grupa && values.kategorija && values.proizvodjac && values.model && values.godina && !values.tipVozila) {
		return 'tipVozila'
	}

	if (values.grupa && values.kategorija && values.proizvodjac && values.godina && !values.tipVozila && !values.model) {
		return 'tipVozila'
	}

	if (values.grupa && values.kategorija && values.proizvodjac && !values.model && !values.godina && !values.tipVozila) {
		return 'godina'
	}

	if (values.grupa && values.kategorija && !values.proizvodjac && !values.model && !values.godina && !values.tipVozila) {
		return 'proizvodjac'
	}

	if (values.grupa && !values.kategorija && !values.proizvodjac && !values.model && !values.godina && !values.tipVozila) {
		return 'kategorija'
	}

	if (!values.grupa && !values.kategorija && !values.proizvodjac && !values.model && !values.godina && !values.tipVozila) {
		return 'grupa'
	}

	if (!values.grupa && !values.kategorija && values.proizvodjac && !values.model && !values.godina && !values.tipVozila) {
		return 'grupa'
	}

	if (!values.grupa && !values.kategorija && values.proizvodjac && values.model && !values.godina && !values.tipVozila) {
		return 'grupa'
	}

	if (values.grupa && !values.kategorija && values.proizvodjac && !values.model && !values.godina && !values.tipVozila) {
		return 'kategorija'
	}

	if (values.grupa && !values.kategorija && values.proizvodjac && values.model && !values.godina && !values.tipVozila) {
		return 'kategorija'
	}

	return 'parts'
}

export const getFilteredFieldsPayload = ({ group, urlValues }) => {
	if (!group || group === 'svi-djelovi') {
		const payload = {}

		if (urlValues?.grupa) payload[urlPartsDefault.grupa.key] = urlValues.grupa
		if (urlValues?.kategorija) payload[urlPartsDefault.kategorija.key] = urlValues.kategorija
		if (urlValues?.brend_automobila) payload[urlPartsDefault.proizvodjac.key] = urlValues.brend_automobila
		if (urlValues?.model_automobila) payload[urlPartsDefault.model.key] = urlValues.model_automobila
		if (urlValues?.godina) payload[urlPartsDefault.godina.key] = urlValues.godina
		if (urlValues?.tip_vozila) payload[urlPartsDefault["tip-vozila"].key] = urlValues.tip_vozila

		return payload
	}

	if (group === 'gume') {
		const payload = {}

		if (urlValues?.sirina) payload[urlPartsGume.sirina.key] = urlValues.sirina
		if (urlValues?.visina) payload[urlPartsGume.visina.key] = urlValues.visina
		if (urlValues?.promjer) payload[urlPartsGume.promjer.key] = urlValues.promjer
		if (urlValues?.sezona) payload[urlPartsGume.sezona.key] = urlValues.sezona
		if (urlValues?.tip_vozila_gume) payload[urlPartsGume["tip-vozila"].key] = urlValues.tip_vozila_gume
		if (urlValues?.proizvodjac) payload[urlPartsGume.proizvodjac.key] = urlValues.proizvodjac

		return payload
	}

	if (group === 'akumulatori') {
		const payload = {}

		if (urlValues?.napon) payload[urlPartsAkumulatori.napon.key] = urlValues.napon
		if (urlValues?.kapacitet) payload[urlPartsAkumulatori.kapacitet.key] = urlValues.kapacitet
		if (urlValues?.sirina) payload[urlPartsAkumulatori.sirina.key] = urlValues.sirina
		if (urlValues?.visina) payload[urlPartsAkumulatori.visina.key] = urlValues.visina
		if (urlValues?.duzina) payload[urlPartsAkumulatori.duzina.key] = urlValues.duzina
		if (urlValues?.proizvodjac) payload[urlPartsAkumulatori.proizvodjac.key] = urlValues.proizvodjac

		return payload
	}

	if (group) {
		const payload = {}

		if (urlValues?.kategorija) payload[urlPartsOstalo.kategorija.key] = urlValues.kategorija
		if (urlValues?.proizvodjac) payload[urlPartsOstalo.proizvodjac.key] = urlValues.proizvodjac

		return payload
	}
}
