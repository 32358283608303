import initialState from 'SRC/modules/redux/initialState'
import actionTypes from 'SRC/modules/redux/actionTypes'

export const vehiclePartDetailReducer = (state = initialState.vehicleParts.detail, action) => {
  switch (action.type) {
    case actionTypes.SET_VEHICLE_PARTS_ITEM:
      return { ...state, item: action.payload }
    case actionTypes.SET_VEHICLE_PARTS_ITEM_LOADING:
      return { ...state, isLoading: action.payload }
    case actionTypes.SET_VEHICLE_PARTS_SIMILAR_PRODUCTS:
      const product = state.item

      // remove current product from similar products
      const similarProducts = action.payload.filter(item => item.kod_proizvoda !== product.kod_proizvoda)

      return {
        ...state,
        similarProducts: {
          elements: similarProducts,
          isLoading: false
        }
      }
    case actionTypes.SET_VEHICLE_PARTS_SIMILAR_PRODUCTS_LOADING:
      return {
        ...state,
        similarProducts: {
          ...state.similarProducts,
          isLoading: action.payload
        }
      }
    default:
      return state
  }
}
