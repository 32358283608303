import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from 'next-translate/useTranslation'
import { connect } from 'react-redux'
import Link from 'next/link'
import { AdFilterField } from 'SRC/ui/FormElementsNew'
import { getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import ReactSVG from 'react-svg'

import translations from '../../../../../../public/data/translations.json'

const CategoriesFilterMobile = ({ category }) => {
  const { t, lang } = useTranslation('common')

  const locale = lang?.toUpperCase() || 'ME'

  return (
    <AdFilterField key='active-categories-filter'>
      <label>{t('Kategorije')}</label>

      <Link
        href={{ pathname: '/categories', query: { targetPage: `${translations.pretragaOglasa[locale]}` } }}
        as={{ pathname: `${translations.kategorije[locale]}`, query: { targetPage: `${translations.pretragaOglasa[locale]}` } }}
      >
        <a className='select-dropdown-picker' style={{ color: '#000' }}>
          <>
            <ReactSVG
              src='/icons/022.pretraga-polja-dropdown-strelica.svg'
              beforeInjection={svg => {
                svg.setAttribute('style', 'width: 20px; height: auto; margin-right: 5px;')
              }}
              className='select-dropdown-picker__icon-svg'
            />

            <label className={category ? 'select-dropdown-selected' : ''}>
              {category ? category.title : t('Izaberi')}
            </label>
          </>
        </a>
      </Link>
    </AdFilterField>
  )
}

const mapStateToProps = state => {
  return {
    category: getCurrentSubCategory(state)
  }
}

CategoriesFilterMobile.propTypes = {
  category: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(CategoriesFilterMobile)
