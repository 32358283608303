import PropTypes from 'prop-types'
import React from 'react'
import useTranslation from 'next-translate/useTranslation'

export const ShowDetailBtn = ({onClick, disabled}) => {
  const { t } = useTranslation('common')

  return (
    <button
      className={`filter-buttons-toggle-detail ${disabled ? 'filter-buttons-toggle-detail__disabled' : ''}`}
      onClick={!disabled ? e => { e.preventDefault(); onClick(); } : null}
    >
      <span>{t('DETALJNA PRETRAGA')}</span>
    </button>
  )
}

ShowDetailBtn.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}
