import PropTypes from 'prop-types'
import React from 'react'
import { Logo } from '../Logo'
import { Status } from '../../Status'
import { Name } from '../Name'
import { PremiumPartner } from '../PremiumPartner'
import { AddressVerification } from '../../AddressVerification'
import { PhoneVerification } from '../../PhoneVerification'
import { EmailVerification } from '../../EmailVerification'
import { Address } from '../Address'

export const Container = ({user}) => {
  return (
    <div className='sidebar-user-main-info'>
      <Logo key='logo' src={user.image && user.image.fd} alt={user.image && user.image.alt} isPro={user.isPro} />
      <div className='sidebar-user-status' key='status'>
        <Status status={user.status} />
        <Name name={user.name} username={user.login} />
        <div className='sidebar-user-verifikacija'>
          <PhoneVerification isVerified={user.isPhoneVerified} needShowText={false} />
          <EmailVerification isVerified={user.isEmailVerified} needShowText={false} />
          <AddressVerification isVerified={user.isAddressVerified} needShowText={false} />
        </div>
        <Address addressCount={user.addressCount} city={user.city} />
      </div>
    </div>
  )
}

Container.propTypes = {
  user: PropTypes.shape({
    image: PropTypes.shape({
      fd: PropTypes.string,
      alt: PropTypes.string
    }),
    status: PropTypes.string,
    name: PropTypes.string,
    login: PropTypes.string,
    isPhoneVerified: PropTypes.bool,
    isEmailVerified: PropTypes.bool,
    isAddressVerified: PropTypes.bool,
    addressCount: PropTypes.number,
    city: PropTypes.string,
    isPro: PropTypes.bool.isRequired
  }).isRequired
}

Container.displayName = 'UserCardBusiness'
