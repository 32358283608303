import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from 'next-translate/useTranslation'
import { withRouter } from 'next/router'
import Link from 'next/link'
import ReactSVG from 'react-svg'

import translations from '../../../../../../public/data/translations.json'

const ItemContent = ({ text1, text2, icon }) => (
	<React.Fragment>
		<ReactSVG src={icon} beforeInjection={svg => {
				svg.setAttribute('style', 'width: auto; margin-top: 5px; height: 35px;')
			}} wrapper='span' />

		<div className='filter-navigation-item-text'>
			<p>{text1}</p>
			<p>{text2}</p>
		</div>
	</React.Fragment>
)

const renderItem = ({ text1, text2, icon, isActive }) => {
	if (!isActive) {
		return (
			<a className='filter-navigation-item'>
				<ItemContent text1={text1} text2={text2} icon={icon} />
			</a>
		)
	}

	return (
		<a className='filter-navigation-item active' href='javascript:void(0)'>
			<ItemContent text1={text1} text2={text2} icon={icon} />
		</a>
	)
}

const renderTextSearchItem = ({ isTextSearchActive, textFilterText1, textFilterText2, textFilterIcon, textSearchDisabled, locale }) => {
	if (isTextSearchActive) return renderItem({ text1: textFilterText1, text2: textFilterText2, icon: textFilterIcon, isActive: true })

	if (textSearchDisabled) {
		return (
			<a className='filter-navigation-item' href='javascript:void(0)'>
				<ItemContent text1={textFilterText1} text2={textFilterText2} icon={textFilterIcon} />
			</a>
		) 
	}

	return (
		<Link
			key='pretraga-po-tekstu'
			href={{ pathname: '/mobileAdFilter', query: { page: translations.tekstualnaPretraga[locale] } }}
			as={`/${translations.pretragaOglasa[locale]}/${translations.tekstualnaPretraga[locale]}`}
		>
			{renderItem({
				text1: textFilterText1,
				text2: textFilterText2,
				icon: textFilterIcon,
				isActive: false
			})}
		</Link>
	)
}

const NavigationMobile = ({ router, textSearchDisabled }) => {
  const { t, lang } = useTranslation('common')

	const locale = lang?.toUpperCase() || 'ME'

	const isTextSearchActive = router.asPath === `/${translations.pretragaOglasa[locale]}/${translations.tekstualnaPretraga[locale]}`

	const textFilterText1 = t('Pretraga')
	const textFilterText2 = t('po tekstu')
	const textFilterIcon = '/icons/pretraga-po-tekstu.svg'
	const categoryFilterText1 = t('Pretraga')
	const categoryFilterText2 = t('po filterima')
	const categoryFilterIcon = '/icons/pretraga-po-filterima.svg'

  return (
		<div className='filter-nav-mobile'>
			{renderTextSearchItem({ isTextSearchActive, textFilterText1, textFilterText2, textFilterIcon, textSearchDisabled, locale })}

			{!isTextSearchActive ?
				renderItem({
					text1: categoryFilterText1,
					text2: categoryFilterText2,
					icon: categoryFilterIcon,
					isActive: true
				}) : (
					<Link
						key='pretraga-po-filterima'
						href={{ pathname: '/mobileAdFilter', query: { page: 'category-filter' } }}
						as={`/${translations.pretragaOglasa[locale]}`}
					>
						{renderItem({
							text1: categoryFilterText1,
							text2: categoryFilterText2,
							icon: categoryFilterIcon,
							isActive: false
						})}
					</Link>
				)
			}
		</div>
	)
}

NavigationMobile.propTypes = {
  router: PropTypes.shape({
    asPath: PropTypes.string.isRequired
  }),
	textSearchDisabled: PropTypes.bool
}

export default withRouter(NavigationMobile)
