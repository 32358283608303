import { combineReducers } from 'redux'
import { vehiclePartsListReducer } from './list/reducer'
import { vehiclePartDetailReducer } from './detail/reducer'
import { vehiclePartsFilterReducer } from './filter/reducer'

export const vehiclPartsReducer = combineReducers({
  list: vehiclePartsListReducer,
  detail: vehiclePartDetailReducer,
  filter: vehiclePartsFilterReducer
})
