import React from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'
import Link from 'next/link'
import config from 'SRC/config/config.json'

export const Item = ({ id, title, icon, iconSvg, href, as, isActive }) => {
  const activeItemRef = React.useRef(null)

  React.useEffect(() => {
    const container = document.querySelector('.category-horizontal-menu-mobile-items')
    if (activeItemRef && activeItemRef.current && container) {
      container.scrollTo(activeItemRef.current.offsetLeft, 0)
    }
  })

  return !isActive ? (
    <Link href={href} as={as}>
      <a className='category-horizontal-menu-mobile-item'>
        <ReactSVG
          src={`${config.mediaCDN}${iconSvg}`}
          beforeInjection={svg => {
            svg.setAttribute('style', 'width: 45px; max-height: 45px; margin: 0; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);')
          }}
          wrapper='span'
          className='svg-container'
        />
        <p>{title}</p>
      </a>
    </Link>
  ) : (
    <a className='category-horizontal-menu-mobile-item  active' href='javascript:void(0)' ref={activeItemRef}>
      <ReactSVG
        src={`${config.mediaCDN}${iconSvg}`}
        beforeInjection={svg => {
          svg.setAttribute('style', 'width: 45px; max-height: 45px; margin: 0; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);')
        }}
        wrapper='span'
        className='svg-container'
      />
      <p>{title}</p>
    </a>
  )
}

Item.propTypes = {
  id: PropTypes.number.isRequired,
  icon: PropTypes.string,
  iconSvg: PropTypes.string,
  title: PropTypes.string.isRequired,
  href: PropTypes.shape({
    pathname: PropTypes.string,
    query: PropTypes.object
  }).isRequired,
  as: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired
}
